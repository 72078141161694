export function extractInviteData(inviteData) {
  return {
    _id: inviteData._id,
    activeLang: inviteData.activeLang,
    defaultLang: inviteData.defaultLang,
    email: inviteData.email,
    name: inviteData.name,
    firstName: inviteData.firstName,
    lastName: inviteData.lastName,
    assessment: {
      _id: inviteData.assessment._id,
      title: inviteData.assessment.title,
      time: inviteData.assessment.time,
      tests: inviteData.assessment.tests,
      assessmentGuidelines: inviteData.assessment.assessmentGuidelines,
      description: inviteData.assessment.description,
      isProctoringEnabled:
        inviteData.assessment.isAntiCheatingSettingEnabled === true,
      languages: inviteData.assessment.languages, //List of languages for the dropdown
      details: inviteData.assessment.details, //assessmentGuidelines will be inside it - language wise filter
      testEndingPage: inviteData.assessment.testEndingPage,
    },
    flowType: inviteData.flowType,
    tests: inviteData.tests.map((test) => ({
      _id: test._id,
      title: test.title,
      guidelines: test.guidelines,
      languageWiseGuidelines: test.languageWiseGuidelines,
      aboutUs: test.aboutUs,
      totalQuestionsCount: test.totalQuestionsCount,
      time: test.time,
      complexity: test.complexity,
      logo: test.logo,
      category: test.category,
      questions: test.questions.map((question) => ({
        _id: question._id,
        attemptStatus: question.attemptStatus,
        answerTitle: question.answerTitle,
        answers: question.answers.map((answer) => ({
          _id: answer._id,
          ratingScalePosition: answer.ratingScalePosition,
          mediaUrl: answer.mediaUrl,
          answer: answer.answer,
          languageWiseAnswers: answer.languageWiseAnswers,
          points: answer.points
        })),
        category: question.category,
        mediaType: question.mediaType,
        complexity: question.complexity,
        groupQuestionTypeDetails: question.groupQuestionTypeDetails,
        // langWiseQuestion: question.langWiseQuestion.map((langQuestion) => ({
        //   _id: langQuestion._id,
        //   question: langQuestion.question,
        //   mediaUrl: langQuestion.mediaUrl,
        //   language: langQuestion.language,
        // })),
        langWiseQuestion: question.langWiseQuestion,
        responseType: question.responseType,
      })),
    })),
  };
}
